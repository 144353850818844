import React from "react"
import Header from "./header.component"
import "./../styles/app.scss"

const Layout = ({ children }) => (
  <div className="page">
    <Header />
    <div className="page__content">{children}</div>
    <footer className="footer">
      <div className="footer__content container">
        <p className="paragraph">info@benk-tuinaannemers.be</p>
        <p className="paragraph">Centrum-Zuid 2603 <br />3530 Houthalen-Helchteren</p>
        <p className="paragraph">BE 0668.522.911</p>
      </div>
    </footer>
  </div>
)
export default Layout
