import React from "react"
import { AnchorLink } from "gatsby-plugin-anchor-links"

const Navbar = () => (
  <nav className="nav">
    <ul className="nav__list">
      <li className="nav__item">
        <AnchorLink className="nav__link" to="/#about">
          Over Ons
        </AnchorLink>
      </li>
      <li className="nav__item">
        <AnchorLink className="nav__link" to="/#services">
          Diensten
        </AnchorLink>
      </li>
      <li className="nav__item">
        <AnchorLink className="nav__link" to="/#gallery">
          Galerij
        </AnchorLink>
      </li>
      <li className="nav__item">
        <AnchorLink className="nav__link" to="/#jobs">
          Vacatures
        </AnchorLink>
      </li>
    </ul>
  </nav>
)

export default Navbar
