import React from "react"

const PrimaryButton = ({
  label,
  onClick,
  onDisabled,
  name = "button button__primary",
  type = "button",
}) => (
  <button disabled={onDisabled} type={type} onClick={onClick} className={name}>
    {label}
  </button>
)

export default PrimaryButton
