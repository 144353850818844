import React from "react"
import Navbar from "./navbar.component"
import { Link } from "gatsby"

const Header = () => (
  <header className="header">
    <div className="header__content container">
      <div className="header__left">
        <Link className="header__link" to="/">
          <h1 className="heading__primary">Benk Tuinaannemers</h1>
        </Link>
      </div>
      <div className="header__right">
        <Navbar />
      </div>
    </div>
  </header>
)

export default Header
