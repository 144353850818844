import React, { useState } from "react"
import axios from "axios"
import PrimaryButton from "./primary-button.component"

const Form = () => {
  const [serverState, setServerState] = useState({
    submitting: false,
    status: "",
  })

  const onSubmit = e => {
    e.preventDefault()

    const form = e.target
    setServerState({ submitting: true })

    const xhr = new XMLHttpRequest()
    xhr.open(form.method, form.action)
    xhr.setRequestHeader("Accept", "application/json")
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return
      if (xhr.status === 200) {
        handleServerResponse(
          true,
          "Bedankt voor je bericht. We nemen zo snel mogelijk contact op.",
          form
        )
      } else {
        handleServerResponse(false, "Er ging iets fout", form)
      }
    }
    xhr.send(new FormData(form))
  }

  const handleServerResponse = (ok, message, form) => {
    setServerState({ submitting: false, status: message })
    if (ok) {
      form.reset()
    }
  }

  const renderStatus = () => {
    if (serverState && serverState.status) {
      return (
        <div className="form__status">
          <p className="paragraph form__status--message">
            {serverState.status}
          </p>
        </div>
      )
    }
  }

  return (
    <form
      method="POST"
      onSubmit={onSubmit}
      action="https://getform.io/f/cbfe2956-2c2f-4881-a74b-0a5559fa0a7f"
      className="form"
    >
      {renderStatus()}

      <div className="form__left">
        <div className="form__item">
          <label htmlFor="name" className="form__label">
            Naam
          </label>
          <input
            required
            name="name"
            id="name"
            type="text"
            className="form__input"
            placeholder="Uw naam"
            aria-label="name"
          />
        </div>
        <div className="form__item">
          <label htmlFor="email" className="form__label">
            Email
          </label>
          <input
            required
            name="email"
            id="email"
            type="email"
            placeholder="Uw email"
            className="form__input"
            aria-label="email"
          />
        </div>
      </div>
      <div className="form__right">
        <div className="form__item">
          <label htmlFor="description" className="form__label">
            Description
          </label>
          <textarea
            id="description"
            name="description"
            placeholder="Uw bericht..."
            className="form__input form__input--textarea"
            aria-label="description"
          />
        </div>
      </div>
      <div className="form__action">
        <PrimaryButton
          type="submit"
          onDisabled={serverState.submitting}
          label="Verzenden"
        />
      </div>
    </form>
  )
}

export default Form
